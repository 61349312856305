import React from 'react';
import { makeStyles, Button, CircularProgress, ButtonProps } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function ProgressButton({ loading, ...props }: ButtonProps & { loading?: boolean }) {
    const classes = useStyles();
    return <div className={classes.wrapper}>
        <Button variant="contained" {...props} disabled={props.disabled || loading} />
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
}
