import React from 'react'
import FormHv from './Pages/FormHV'
import Confirmation from './Pages/Confirmation'
import { Route, BrowserRouter } from 'react-router-dom'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import useOneVcardTheme from './Hooks/useOneVcardTheme'
import { Redirect } from 'react-router-dom'
import Auth from './Pages/Auth'

function App() {
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Auth.getAuth() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }
    />
  )
  const theme = useOneVcardTheme()
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Route exact={true} path='/'>
          <FormHv />
        </Route>
        <PrivateRoute path='/confirmation' component={Confirmation} />

        <Route render={() => <Redirect to={{ pathname: '/' }} />} />
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
