import { createMuiTheme, useMediaQuery } from '@material-ui/core';
import { useMemo } from 'react';
import useLocalStorage from './useLocalStorage';

/** Hook that generates the theme for clye, it respects dark mode and sets all colors according to Clye design rules */
export default function useOneVcardTheme() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [darkMode] = useLocalStorage<boolean | null>("dark-mode", null);
    const dark = darkMode == null ? prefersDarkMode : darkMode;

    const theme = useMemo(() => {
        return createMuiTheme({
            typography: { fontFamily: "Montserrat, Roboto, sans-serif" },
            palette: {
                primary: {
                    main: "#51DDAA"
                },
                background: dark ? {
                    default: "#202020",
                    paper: "#303030"
                } : {},
                type: dark ? 'dark' : 'light',
            },
        });
    }, [prefersDarkMode, darkMode]);

    return theme;
}