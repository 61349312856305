import React, { useState, useEffect } from 'react'
import {
  Container,
  TextField,
  Checkbox,
  CardActions,
  FormControlLabel,
  Link,
  Typography,
  Button,
  Card,
} from '@material-ui/core'
import Auth from './Auth'
import Tooltip from '@material-ui/core/Tooltip'
import { useHistory } from 'react-router-dom'
import tileData from '../data/tileData'
import logo from '../assets/logo.svg'
import ProgressButton from '../Components/ProgressButton'
import '../App.css'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid'
// import firebase from '../firebase'
import useStyles from '../style/style_FormHV'
import Header from '../Components/Header'
// const db = firebase.firestore()
function FormHV(props) {
  const classes = useStyles()
  const [link, setLink] = useState('')
  const [accept, setAccept] = useState(false)
  const [linkHG, setLinkHG] = useState('')
  const [chosen, setChosen] = useState(0)
  const [name, setName] = useState('')
  const [text, setText] = useState('')

  useEffect(() => {
    // const fetchImages = async () => {
    //   const imagesCollection = await db.collection('images').get()

    //   setImages(
    //     imagesCollection.docs.map((doc) => {
    //       const id = doc.id

    //       const imagename = doc.data().imagename
    //       const url = doc.data().url

    //       return { id: id, imagename: imagename, url: url }
    //     })
    //   )
    // }
    // fetchImages()
    setChosen('')
    setLink('')
    setText('')
    setAccept(false)

    return () => {}
  }, [])

  const history = useHistory()

  const Handleerstellen = async (e) => {
    e.preventDefault()
    Auth.authenticate()
    history.push({
      pathname: '/confirmation',
      state: { link: link, chosen: chosen, name: name, text: text },
    })
  }

  return (
    <>
      <Header></Header>
      <Container maxWidth={'xs'} className={classes.root}>
        <Typography variant='h4'>Virtuellen Hintergrund erstellen</Typography>
        <form className={classes.form} onSubmit={(e) => Handleerstellen(e)}>
          <Tooltip title='Dein Name wird gut sichtbar in der linken Ecke platziert. Alternativ kannst du dieses Feld auch frei lassen.'>
            <TextField
              label='Vorname und Nachname'
              className={classes.input}
              value={name}
              placeholder='Elias Drocksler'
              fullWidth
              inputProps={{
                maxLength: '60',
              }}
              onChange={(e) => setName(e.target.value)}
            />
          </Tooltip>
          <div className={classes.linkandtext}>
            <Tooltip title='Gib einen Link ein, auf den der QR Code verweisen soll. Alternativ kannst du dieses Feld auch frei lassen.'>
              <TextField
                label='Link'
                value={link}
                className={classes.link}
                fullWidth
                placeholder='https://www.linkedin.com/in/eliasdrocksler'
                inputProps={{
                  pattern:
                    '(http[s]?://|www.|ftp://){1,2}([-a-zA-Z0-9_]{2,256}.[a-z]{2,4}(/?[-a-zA-Z0-9@:%_+.~#?&/=]+)?)',
                }}
                onChange={(e) => {
                  setLink(e.target.value)
                }}
              />
            </Tooltip>
            <Tooltip title='Gib hier eine Beschreibung zu Deinem Link ein. Alternativ kannst du dieses Feld auch frei lassen.'>
              <TextField
                label='Link Beschreibung'
                value={text}
                className={classes.text}
                fullWidth
                inputProps={{
                  maxLength: '23',
                }}
                placeholder='Link öffnen'
                onChange={(e) => {
                  setText(e.target.value)
                }}
              />
            </Tooltip>
          </div>
          {/* <TextField
          label='link Background (example:Unsplach)'
          value={linkHG}
          id='linkHG'
          className={classes.input}
          fullWidth
          onChange={(e) => setLinkHG(e.target.value)}
          disabled={chosen !== {}}
          onClick={(e) => {
            setChosen({})
            setLinkHG('')
          }}
        /> */}
          <Container maxWidth='sm'>
            <Grid container style={{ marginBottom: '10%' }} spacing={2}>
              {tileData.map((tile) => {
                return (
                  <Grid item xs={12} md={6} sm={6} lg={6} key={tile.id}>
                    <Card className={classes.card}>
                      <CardActionArea onClick={(e) => setChosen(tile.id)}>
                        <CardMedia className={classes.media} image={tile.img} />

                        <Grid container className={classes.overlay}>
                          <Grid item xs={12} sm={8} md={8} lg={8}>
                            <span className={classes.title}>{tile.title}</span>
                          </Grid>

                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            <Button
                              type='button'
                              className={classes.Button}
                              disabled={chosen === tile.id}
                            >
                              &#10004;
                            </Button>
                          </Grid>
                        </Grid>
                      </CardActionArea>
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
          </Container>

          <FormControlLabel
            control={
              <Checkbox
                checked={accept}
                onChange={(e, c) => setAccept(c)}
                name='checkedB'
                color='primary'
              />
            }
            label={
              <span>
                Akzeptiere unsere{' '}
                <Link href='https://www.onevcard.de/datenschutz' target='blank'>
                  Datenschutzbestimmungen
                </Link>
              </span>
            }
          />

          <CardActions className={classes.buttons}>
            <ProgressButton
              type='submit'
              color='primary'
              variant='contained'
              disableElevation
              disabled={!(accept && (chosen != 0 || linkHG))}
            >
              Erstellen{' '}
            </ProgressButton>
          </CardActions>
        </form>
      </Container>
    </>
  )
}

export default FormHV
