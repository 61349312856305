import React, { useState, useEffect } from 'react'
import '../App.css'
import { Stage, Layer, Image, Rect, Text, Group } from 'react-konva'
import useImage from 'use-image'
import useStyles from '../style/style_Confirmation'
import logo from '../assets/logo.svg'
import ProgressButton from '../Components/ProgressButton'
import onevcardlogo from '../images/OVC.png'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { Container, Typography } from '@material-ui/core'
import firebase from '../firebase'
import download from 'downloadjs'
import EasyQRCode from 'easyqrcodejs'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import foto1 from '../images/Black Wall.jpg'
import foto2 from '../images/Frames.jpg'
import foto3 from '../images/In the mountains.jpg'
import foto4 from '../images/My Homeoffice.jpg'
import foto5 from '../images/White wall.jpg'
import foto6 from '../images/Street lights.jpg'
import Header from '../Components/Header'

import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share'
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share'

const db = firebase.firestore()

function Confirmation(props) {
  const classes = useStyles()

  const DialogTitle = withStyles(useStyles)((props) => {
    const { children, onClose, ...other } = props
    return (
      <MuiDialogTitle
        disableTypography
        className={classes.rootmodal}
        {...other}
      >
        <Typography variant='h6'>{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  })

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent)

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions)

  const [backgroundchosen, setBackgroundchosen] = useState('')
  const [name, setName] = useState('')
  const [text, setText] = useState('Datei Anzeigen')
  const [readyImage, setReadyImage] = useState('')
  const [open, setOpen] = React.useState(false)
  const [qrcodelink, setQrcodeLink] = useState('')
  const handleClickOpen = () => {
    setOpen(true)
  }
  const history = useHistory()

  const neueHintergrund = () => {
    setOpen(false)
    history.push({
      pathname: '/',
    })
  }
  const handleClose = () => {
    setOpen(false)
  }

  const init = () => {
    setName(props.location.state.name)
    setText(props.location.state.text)
    switch (props.location.state.chosen) {
      case 1:
        setBackgroundchosen(foto1)
        console.log('foto 1 ' + foto1)
        break
      case 2:
        setBackgroundchosen(foto2)
        console.log('foto 2 ' + foto2)
        break
      case 3:
        setBackgroundchosen(foto3)
        console.log('foto 3 ' + foto3)
        break
      case 4:
        setBackgroundchosen(foto4)
        console.log('foto 4 ' + foto4)
        break
      case 5:
        setBackgroundchosen(foto5)
        console.log('foto 5 ' + foto5)
        break
      case 6:
        setBackgroundchosen(foto6)
        console.log('foto 6 ' + foto6)
        break
      default:
        setBackgroundchosen(foto6)
        break
    }
  }

  useEffect(() => {
    init()
    if (props.location.state.link) {
      console.log('creatiing qrcode')

      new EasyQRCode(document.getElementById('qrcode'), {
        text: props.location.state.link,
        colorLight: 'transparent',
        width: 150,
        height: 150,
        quietZone: 10,
        quietZoneColor: 'transparent',
        logo: onevcardlogo,
        logoWidth: 50, // width. default is automatic width
        logoHeight: 50,
        logoBackgroundTransparent: true,
        onRenderingEnd: function (options, dataURL) {
          console.info(dataURL)
          setQrcodeLink(dataURL)
        },
      })
    }
  }, [])

  const LionImage = () => {
    const [image] = useImage(backgroundchosen)
    return <Image image={image} width={960} height={540} />
  }

  const QRcodeImage = () => {
    const [image] = useImage(qrcodelink)
    return <Image image={image} width={80} height={80} x={380} y={20} />
  }
  const stageRef = React.useRef(null)
  const handleExport = async () => {
    const uri = stageRef.current.toDataURL({
      pixelRatio: 2 / scale, // or other value you need
    })
    console.log(uri)
    setReadyImage(uri)
    download(uri, 'Virtueller_Hintergrund_oneVcard')
    handleClickOpen()
    //Drehung von URL auf x-Achse
    // const image = await Jimp.read(uri)
    // // flip function also known as mirror function
    // image.flip(true, false).getBase64(Jimp.MIME_JPEG, function (err, src) {
    //   console.log('rb is \n')
    //   setReadyImage(src)
    //   download(src, 'Virtueller_Hintergrund_oneVcard')
    //   handleClickOpen()
    // })
    await db
      .collection('virtueller_hintergrund')
      .doc()
      .set({
        // foto: readyImage,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      })
  }
  const [size, setSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  React.useEffect(() => {
    const checkSize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', checkSize)
    return () => window.removeEventListener('resize', checkSize)
  }, [])
  const CANVAS_VIRTUAL_WIDTH = 500
  const CANVAS_VIRTUAL_HEIGHT = 600
  const scale = Math.min(
    size.width / CANVAS_VIRTUAL_WIDTH,
    size.height / CANVAS_VIRTUAL_HEIGHT
  )
  const scalex = size.width / 1250
  const BackImage = () => {
    const [image] = useImage(backgroundchosen)
    return <Image image={image} width={960} height={540} />
  }
  const QRcodeImageback = () => {
    const [image] = useImage(qrcodelink)
    return <Image image={image} width={120} height={120} x={800} y={20} />
  }
  return (
    <>
      <Header></Header>
      <Container className={classes.rootVH}>
        <Typography variant='h4'>Dein virtueller Hintergrund</Typography>
        <Button className={classes.savebutton} onClick={handleExport}>
          Speichern
        </Button>

        <div
          id='qrcode'
          style={{
            display: 'non',
            visibility: 'hidden',
            width: 200,
            height: 200,
          }}
        ></div>
        <Stage
          width={960 * scalex}
          height={540 * scalex}
          scaleX={scalex}
          scaleY={scalex}
        >
          <Layer>
            <Group>
              <BackImage />
            </Group>
            {name ? (
              <Group>
                <Rect
                  x={20}
                  y={20}
                  width={20 + name.length * 9}
                  height={40}
                  fillLinearGradientStartPoint={{ x: -50, y: -50 }}
                  fillLinearGradientEndPoint={{ x: 50, y: 50 }}
                  fillLinearGradientColorStops={[0, '#fafafc ', 1, '#fafafc']}
                  opacity={1}
                  cornerRadius={5}
                />
                <Text
                  text={name}
                  x={20}
                  y={20}
                  verticalAlign='middle'
                  height={40}
                  width={20 + name.length * 9}
                  align='center'
                  fontSize={16}
                  fontStyle='bold'
                />
              </Group>
            ) : (
              <></>
            )}
            <Group>
              <Rect
                x={800}
                y={480}
                width={120}
                height={33}
                fillLinearGradientStartPoint={{ x: -50, y: -50 }}
                fillLinearGradientEndPoint={{ x: 50, y: 50 }}
                fillLinearGradientColorStops={[0, '#fafafc ', 1, '#fafafc']}
                opacity={1}
                cornerRadius={5}
              />
              <Text
                text='powered by'
                x={800}
                y={481}
                padding={5}
                width={120}
                align='center'
                fontSize={12}
              />
              <Text
                text='onevcard.de'
                x={800}
                y={493}
                padding={5}
                width={120}
                align='center'
                fontSize={12}
              />
            </Group>
            {props.location.state.link ? (
              <Group>
                <Group>
                  <Rect
                    x={800}
                    y={20}
                    width={120}
                    height={120}
                    fillLinearGradientStartPoint={{ x: -50, y: -50 }}
                    fillLinearGradientEndPoint={{ x: 50, y: 50 }}
                    fillLinearGradientColorStops={[0, '#fafafc ', 1, '#fafafc']}
                    opacity={1}
                    cornerRadius={5}
                  />
                  <QRcodeImageback></QRcodeImageback>
                </Group>
                <Group>
                  <Rect
                    x={800}
                    y={150}
                    width={120}
                    height={28 + (text.length / 14) * 13}
                    fillLinearGradientStartPoint={{ x: -50, y: -50 }}
                    fillLinearGradientEndPoint={{ x: 50, y: 50 }}
                    fillLinearGradientColorStops={[0, '#fafafc ', 1, '#fafafc']}
                    opacity={1}
                    cornerRadius={5}
                  />
                  <Text
                    text={text ? text : 'Link aufrufen'}
                    x={800}
                    y={150}
                    verticalAlign='middle'
                    height={28 + (text.length / 14) * 13}
                    width={120}
                    align='center'
                    fontSize={14}
                  />
                </Group>
              </Group>
            ) : (
              <></>
            )}
          </Layer>
        </Stage>
        <Stage
          width={960 * scale}
          style={{ display: 'none' }}
          height={540 * scale}
          scaleX={scale}
          scaleY={scale}
          ref={stageRef}
        >
          <Layer>
            <BackImage />
            {name ? (
              <Group>
                <Rect
                  x={20}
                  y={20}
                  width={20 + name.length * 9}
                  height={40}
                  fillLinearGradientStartPoint={{ x: -50, y: -50 }}
                  fillLinearGradientEndPoint={{ x: 50, y: 50 }}
                  fillLinearGradientColorStops={[0, '#fafafc ', 1, '#fafafc']}
                  opacity={1}
                  cornerRadius={5}
                />
                <Text
                  text={name}
                  x={20}
                  y={20}
                  verticalAlign='middle'
                  height={40}
                  width={20 + name.length * 9}
                  align='center'
                  fontSize={16}
                  fontStyle='bold'
                />
              </Group>
            ) : (
              <></>
            )}
            <Group>
              <Rect
                x={800}
                y={480}
                width={120}
                height={33}
                fillLinearGradientStartPoint={{ x: -50, y: -50 }}
                fillLinearGradientEndPoint={{ x: 50, y: 50 }}
                fillLinearGradientColorStops={[0, '#fafafc ', 1, '#fafafc']}
                opacity={1}
                cornerRadius={5}
              />
              <Text
                text='powered by'
                x={800}
                y={481}
                padding={5}
                width={120}
                align='center'
                fontSize={12}
              />
              <Text
                text='onevcard.de'
                x={800}
                y={493}
                padding={5}
                width={120}
                align='center'
                fontSize={12}
              />
            </Group>
            {props.location.state.link ? (
              <Group>
                <Group>
                  <Rect
                    x={800}
                    y={20}
                    width={120}
                    height={120}
                    fillLinearGradientStartPoint={{ x: -50, y: -50 }}
                    fillLinearGradientEndPoint={{ x: 50, y: 50 }}
                    fillLinearGradientColorStops={[0, '#fafafc ', 1, '#fafafc']}
                    opacity={1}
                    cornerRadius={5}
                  />
                  <QRcodeImageback></QRcodeImageback>
                </Group>
                <Group>
                  <Rect
                    x={800}
                    y={150}
                    width={120}
                    height={28 + (text.length / 14) * 11}
                    fillLinearGradientStartPoint={{ x: -50, y: -50 }}
                    fillLinearGradientEndPoint={{ x: 50, y: 50 }}
                    fillLinearGradientColorStops={[0, '#fafafc', 1, '#fafafc']}
                    opacity={1}
                    cornerRadius={5}
                  />
                  <Text
                    text={text ? text : 'Link aufrufen'}
                    x={800}
                    y={150}
                    verticalAlign='middle'
                    height={28 + (text.length / 14) * 13}
                    width={120}
                    align='center'
                    fontSize={14}
                  />
                </Group>
              </Group>
            ) : (
              <></>
            )}
          </Layer>
        </Stage>

        <Dialog
          onClose={handleClose}
          aria-labelledby='customized-dialog-title'
          open={open}
        >
          <DialogTitle id='customized-dialog-title' onClose={handleClose}>
            Virtuellen Hintergrund einrichten
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              Dein virtueller Hintergrund wurde bereits heruntergeladen.
            </Typography>
            <Typography gutterBottom>
              Öffne nun die gewünschte Videokonferenz-Software, in der Du Deinen
              virtuellen Hintergrund verwenden möchtest.
            </Typography>
            <Typography gutterBottom>
              Eine Anleitung wie Du den virtuellen Hintergrund in Zoom,
              Microsoft-Teams und in weiteren Programmen einrichtest findest Du{' '}
              <a
                href='https://www.onevcard.de/vb-config'
                style={{ color: '#51ddaa' }}
              >
                hier
              </a>
              .
            </Typography>

            <Typography gutterBottom>
              <p>
                Wir freuen uns, wenn Du Deine Erfahrung über Deine Sozialen
                Medien teilst und uns verlinkst.
              </p>
            </Typography>
            <Grid container style={{ marginTop: '5%' }}>
              <Grid item xs={1} sm={1}></Grid>
              <Grid item xs={2} sm={2}>
                <FacebookShareButton
                  url='https://www.onevcard.de/vb'
                  quote='Ich benutze virtuelle Hintergründe von oneVcard. Wäre das auch was für Dich?'
                  hashtag='#virtuell #virtuellesmeeting #virtuellerhintergrund #digital #digitalisierung #qrcode #smart #smartbusinesscard'
                >
                  <FacebookIcon size={50} round={true} />
                </FacebookShareButton>
              </Grid>
              <Grid item xs={2} sm={2}>
                <TwitterShareButton
                  url='https://www.onevcard.de/vb'
                  title='oneVcard Virtuelle Hintergründe'
                  hashtags={[
                    '#virtuell',
                    '#virtuellesmeeting',
                    '#virtuellerhintergrund',
                    '#digital',
                    '#digitalisierung',
                    ' #qrcode',
                    '#smart',
                    '#smartbusinesscard',
                  ]}
                  related={['onevcard']}
                >
                  <TwitterIcon size={50} round={true}></TwitterIcon>
                </TwitterShareButton>
              </Grid>
              <Grid item xs={2} sm={2}>
                <WhatsappShareButton
                  url='https://www.onevcard.de/vb'
                  title='oneVcard Virtuelle Hintergründe'
                >
                  <WhatsappIcon size={50} round={true}></WhatsappIcon>
                </WhatsappShareButton>
              </Grid>
              <Grid item xs={2} sm={2}>
                <EmailShareButton
                  url='https://www.onevcard.de/vb'
                  subject='oneVcard Virtuelle Hintergründe'
                  body='Ich benutze virtuelle Hintergründe von oneVcard. Wäre das auch was für Dich?'
                >
                  <EmailIcon size={50} round={true}></EmailIcon>
                </EmailShareButton>
              </Grid>
              <Grid item xs={2} sm={2}>
                <a
                  href='https://www.linkedin.com/sharing/share-offsite/?url=www.onevcard.de/vb'
                  //   title='oneVcard Virtuelle Hintergründe'
                  //summary='Ich benutze virtuelle Hintergründe von oneVcard. Wäre das auch was für Dich?'
                >
                  <LinkedinIcon size={50} round={true}></LinkedinIcon>
                </a>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              style={{ backgroundColor: '#51DDAA', color: '#202020' }}
              onClick={neueHintergrund}
            >
              Neuen Hintergrund erstellen
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  )
}
export default Confirmation
