import foto1 from '../images/Black Wall-mini.jpg';
import foto2 from '../images/Frames-mini.jpg';
import foto3 from '../images/In the mountains-mini.jpg';
import foto4 from '../images/My Homeoffice-mini.jpg';
import foto5 from '../images/White wall-mini.jpg';
import foto6 from '../images/Street lights-mini.jpg';
const tileData = [
   {
     img: foto1,id:1,
     title: 'Black Wall',
      author: 'author',
     featured: true,
    },  {
     img: foto2,id:2,
     title: 'Frames',
      author: 'author',
     featured: true,
    },  {
     img: foto3,id:3,
     title: 'In the Mountains',
      author: 'author',
     featured: true,
    },  {
     img: foto4,id:4,
     title: 'My Homeoffice ',
      author: 'author',
     featured: true,
    },  {
     img: foto5,id:5,
     title: 'White Wall',
      author: 'author',
     featured: true,
    }, 
     {
     img: foto6,id:6,
     title: 'Street Lights',
      author: 'author',
     featured: true,
    },
 
  ];
export default tileData;