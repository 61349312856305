import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100%',
    minWidth: '50%',
    paddingBottom: theme.spacing(4),
    '&:hover $focusHighlight': {
      opacity: theme.palette.action.hoverOpacity,
      '@media (hover: none)': {
        opacity: 0,
      },
    },
    '&$focusVisible $focusHighlight': {
      opacity: theme.palette.action.focusOpacity,
    },
  },

  buttons: {
    justifyContent: 'flex-end',
  },
  form: {
    width: '100%',
  },

  logo: { maxWidth: '60%', width: 'auto' },
  loggedIn: {
    position: 'fixed',
    top: theme.spacing(2),
  },
  info: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  input: { marginTop: theme.spacing(3), marginBottom: theme.spacing(3) },
  rootcard: {
    maxWidth: 345,
  },
  media: {
    width: '100%',
    paddingTop: '56.25%', // 16:9
  },
  card: {
    width: '100%',
  },

  cardAction: {
    height: '1%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

  logoVH: { maxWidth: '22%', width: 'auto' },

  Button: {
    position: 'absolute',
    opacity: 1,
    '&:disabled': {
      backgroundColor: '#51DDAA',
      color: '#fafafc',
      opacit: 0.4,
    },
    // '&:hover': {
    //   color: '#fafafc',

    //   backgroundColor: '#51ddaa',
    //   opacity: 1,
    // },
    right: 0,
    color: 'transparent',
    width: '100%',
    top: 0,
    paddingTop: '0.1%',
    paddingBottom: 20,
  },
  title: {
    position: 'absolute',
    backgroundColor: '#202020',
    opacity: 0.8,
    padding: '5%',
    paddingTop: '2%',
    textAlign: 'center',
    width: '100%',
  },
  overlay: {
    color: '#FAFAFC',
    bottom: '20%',
    position: 'absolute',
  },
  linkandtext: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: '10%',
  },
  link: {
    width: '95%',
    marginRight: '2%',
  },
  text: {
    width: '95%',
  },
}))
export default useStyles
