import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  rootVH: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100%',
    minWidth: '50%',
    paddingBottom: theme.spacing(4),
  },

  logo: { maxWidth: '60%', width: 'auto' },

  savebutton: {
    backgroundColor: theme.palette.primary.main,
    color: '#202020',
    padding: '0.75%',
    marginTop: 50,
    marginBottom: -170,
  },
  rootmodal: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))
export default useStyles
