import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import logo from '../images/OVC.png'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import HelpIcon from '@material-ui/icons/Help'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: '2%',
  },
  logoButton: {
    marginLeft: '3%',
    width: 50,
    height: 50,
    marginRight: '1%',
  },
  title: { flexGrow: 1 },
  logo: { width: '100%', height: '100%' },
  addIcon: {
    color: '#fafafc',
    textDecoration: 'none',
    marginRight: '2%',
  },
}))
function Header() {
  const classes = useStyles()
  return (
    <>
      <div className={classes.root}>
        <AppBar position='static' style={{ backgroundColor: '#202020' }}>
          <Toolbar>
            <div
              className={classes.logoButton}
              color='inherit'
              aria-label='menu'
            >
              <img className={classes.logo} src={logo} alt='oneVcard logo' />
            </div>
            <Typography variant='h6' className={classes.title}>
              <a
                href='https://www.onevcard.de/vb'
                style={{
                  color: '#fafafc',
                  textDecoration: 'none',
                  fontSize: '110%',
                }}
              >
                {' '}
                Startseite
              </a>
            </Typography>
            <a href='backgrounds.onevcard.app' className={classes.addIcon}>
              <AddCircleIcon style={{ width: 40, height: 40 }}></AddCircleIcon>
            </a>
            <a
              href='https://www.onevcard.de/vb-config'
              style={{
                color: '#fafafc',
                textDecoration: 'none',
              }}
            >
              <HelpIcon style={{ width: 40, height: 40 }}> </HelpIcon>
            </a>
          </Toolbar>
        </AppBar>
      </div>
    </>
  )
}
export default Header
