import firebase from "firebase/app";
import 'firebase/storage';
// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyCTg5XDgHmsU1NJNUNhty0DN1M8-6ZsADs",
  authDomain: "onevcard-d3cd6.firebaseapp.com",
  databaseURL: "https://onevcard-d3cd6.firebaseio.com",
  projectId: "onevcard-d3cd6",
  storageBucket: "onevcard-d3cd6.appspot.com",
  messagingSenderId: "901434695885",
  appId: "1:901434695885:web:f2e053f40390ed7937c984"
}; 
firebase.initializeApp(firebaseConfig);
 const storage = firebase.storage();
export {storage , firebase as default}




